<template>
  <v-container>
    
    <div class="text-h2" :style="{'font-weight':400, 'letter-spacing': '0.4vw !important;', 'margin':'20px 0 30px 0'}">{{ingredient.charAt(0).toUpperCase() + ingredient.slice(1)}}</div>
    <v-divider></v-divider>
    <div style="margin:20px; line-height:25px">
      <li v-if="description"><a href="#beskrivelse">Beskrivelse</a></li>
      <li v-if="analyzedNutes"><a href="#matvare-analyse">Matvare Analyse</a>
        <ul style="margin-left:20px">
          <li><a href="#matvare-analyse">Hvor kommer energien til matvaren fra?</a></li>
        </ul>
      </li>
      <li v-if="nutrients.length > 0"><a href="#naeringsinnhold">Næringsinnhold</a></li>
    </div>
    <div v-if="description">
      <div class="text-h3" id="beskrivelse" style="margin:20px 0">Beskrivelse</div>
      
      <div v-html="description"></div>
    </div>
    <div v-if="analyzedNutes">
      <div class="text-h3" id="matvare-analyse" style="margin:20px 0">Matvare analyse</div>
      <!--<div v-html="analyzedNutes"></div>-->
      <div class="text-h4" id="energi-analyse" style="margin:20px 0">Hvor kommer energien til matvaren fra?</div>
      <p>Dette er en oversikt over hvordan matvarens fordeling av energi er. Utreningen er basert på antall gram næring i matvaren. Verdiene er oppgitt i Kcal. Hvis du ønsker å finne den totale mengden energi finner du det under "næringsinnhold".</p>
      <p v-if="unknownNutes.length">
        Vi har ikke næringsinnholdet av {{unknowNutesString}} på denne matvaren. Dette vil derfor påvirke analysen av energi innholdet i matvaren.
      </p>
      <div style="max-width:600px; margin: 10px auto 40px"><canvas id="myChart" ref="myChart" width="400" height="400"></canvas></div>
    </div>
    <v-divider></v-divider>
    <div class="text-h3" style="margin:20px 0" id="naeringsinnhold" v-if="nutrients.length > 0">Næringsinnhold</div>
    <v-divider></v-divider>
    <div v-for="nutrientCategory in nutrients" v-bind:key="nutrientCategory.title">
    <div class="text-h4" style="margin:20px 0 ">{{nutrientCategory.title}}</div>
    <v-row class="my-4">
      
                  <v-col cols="4" sm="2"
                  v-for="(nutrient, nutes_index) in nutrientCategory.nutrients" v-bind:key="nutrient.title+nutes_index">
                  <div
                    class="text-center mx-2"
                  >
                  <p class="my-1">
                  {{nutrient.title}} <a v-if="nutrient.link" rel="help" target="_blank" :href="nutrient.link">[?]</a>
                  </p>
                  <v-progress-circular
                      :rotate="-90"
                      :size="90"
                      :width="12"
                      :value="nutrient.value/nutrient.multiplier"
                      :color="nutrient.color"
                    >
                      {{ nutrient.value }} {{nutrient.measurement}}
                    </v-progress-circular>
                    </div>
                  </v-col>
                </v-row>
    </div>
  </v-container>
</template>
<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
  
  let data = {
    ingredient:'',
    nutrients:[],
    unknownNutes:[],
    description:'',
    analyzedNutes:[],
    rawNutes:[]
  }
  if(!(window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendered) && window.__IS_PRERENDERED && !window.__LOADED){
    if(window.__INIT_STATE__){
      console.log("FETCHING FROM INIT STATE");
      data = window.__INIT_STATE__;
      //data.ingredient = window.__INIT_STATE__.ingredient;
    } else {
      console.log("PRERENDERED BUT MISSING INIT STATE...");
    }
  }
  export default {
   metaInfo(){
      return {
        // override the parent template and just use the above title only
        title: this.ingredient.charAt(0).toUpperCase() + this.ingredient.slice(1),
        // all titles will be injected into this template
        titleTemplate: '%s | Brukmat',
        meta: [
          { name: 'description', content: 'Komplett næringsinnhold og annen vitenskapelig data om '+ this.ingredient+'.'}
        ]
      }
    },
    data:()=> (data),
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.getIngredient();
      
    },
    methods: {
      getIngredientFromUrl: function(title, keywords){
        
        console.log("Title:",title, "Keywords:",keywords);
        if(keywords === undefined){
          keywords = [];
        }
        let headers = {
                  'Content-Type': 'application/json'
                };
        // If keyword is empty...
        if(keywords[0] == "" || keywords[0] == " "){
          keywords = [];
        }
        console.log("POST Title:",title, "Keywords:",keywords);
        let body = {
          title:title,
          exact:true,
          keywords:keywords.map(keyword=>keyword.trim())
        };
        let allPromises = [];
        allPromises.push(this.getDescription(title, keywords));
        console.log(body);
        allPromises.push(this.$axios.post('https://api.brukmat.no/ingredients/keyword/', body, {headers:headers}).then(async (res)=>{
          let data = res.data[0];
          // If no data was returned... (couldn't find it)
          if(data == undefined){
            window.location.replace("/404/ingrediens");
          }
          if(data.keywords.length === 0){
            this.ingredient = data.product;
          } else {
            this.ingredient = data.product+" - "+data.keywords.join(", ");
          }
          if(res.data.length > 1){
            console.log("Got more than 1 ingredient from search...");
          }
          this.rawNutes = data;
          this.nutrients = this.putFirstItemLast(await this.formatNutrients(data));
        }));
        Promise.allSettled(allPromises).then(()=>{
          console.log("Finished loading from API", [this.ingredient, this.nutrients, this.description]);
          // Save state to variable
          if(window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendered){
          //this.description = "PRERENDERED!!!";
          const script = document.createElement(`script`);
          let state = {
            ingredient:this.ingredient,
            nutrients:this.nutrients,
            description:this.description
          }
          script.innerHTML = `window.__INIT_STATE__ = ${JSON.stringify(state)}`;
          document.head.appendChild(script);
          window.status = "ready";
          }
        })
        //TODO Save to db
      },
      putFirstItemLast: function(list){
        let firstItem = list.shift();
        console.log("list f is", list);
        list.push(firstItem);
        console.log("list is", list);
        return list;
      },
      formatNutrients: async function(items){
        let dataScheme = await this.$scheme;
        let unflattened = unflattenItem(items);
        console.log(unflattened);
        let nutrients = unflattened.nutrients.map((nutrient)=>{
          //console.log(nutrient.title, dataScheme[nutrient.title]);
          if(dataScheme[nutrient.title]){
            // If hidden
            /*if(dataScheme[nutrient.title].hide == "true"){

            }*/
            return {
              color:dataScheme[nutrient.title].color || 'grey',
              title:dataScheme[nutrient.title].title || nutrient.title,
              value:nutrient.value,
              multiplier:dataScheme[nutrient.title].multiplier || 1,
              measurement:dataScheme[nutrient.title].measurement,
              category:dataScheme[nutrient.title].category || 'Ukategorisert',
              link:dataScheme[nutrient.title].link
            }
          } else {
            return {
              color:'grey',
              title:nutrient.title,
              value:nutrient.value,
              multiplier:1,
              category:"Ukategorisert"
            }
          }
        });
        let nutesWithCategories = nutrients.reduce((acc, nute)=>{
          if(!acc) acc={};
          if(!acc[nute.category]) acc[nute.category] = [];
          acc[nute.category].push(nute);
          return acc;
        }, {});
        let nuteArray = [];
        for(let i in nutesWithCategories){
          nuteArray.push({title:i, nutrients:nutesWithCategories[i]});
        }
        return nuteArray;
      },
      getDescription: async function(title, keywords){
        let body = {
          product:title,
          keywords:keywords
        };
        let headers = {
          'Content-Type': 'application/json'
        };
        console.log(body);
        return this.$axios.post('https://api.brukmat.no/get/ingredient/description', body, {headers:headers})
        .then((res)=>{
          console.log(res.data);
          console.log(res.data.http_code);
          if(res.data.status === 1){
            this.description = res.data.data;
          }
        })
      },
      getIngredient: function(){
        if(!(window.__PRERENDER_INJECTED && window.__PRERENDER_INJECTED.prerendered) && window.__IS_PRERENDERED && !window.__LOADED){
          console.log("Is prerendered");
          window["__LOADED"] = true;
          return;
        }
        let title = this.$route.params.ingredient;
        let keywords = this.$route.params.keywords;
        console.log("Keywords",keywords);
        // If keywords undefined... TODO Tomorrow
        let fullTitle;
        title = this.$decodeURL(title);
        if(keywords == undefined){
          fullTitle = this.$decodeURL(title);
        } else {
          keywords = keywords.split('~');
          keywords = keywords.map(keyword=>{
            return this.$decodeURL(keyword);
          });
          fullTitle = title+" - "+keywords.join(", ");
        }
        console.log("Keywords", keywords);
        // Let's deconstruct the params
        this.$db.then(db_obj=>{
          let val = fullTitle;
          var objectStore = db_obj.transaction(["ingredients"], "readwrite").objectStore("ingredients");
          var request = objectStore.get(val);
          request.onsuccess = async (result)=>{
            let items = result.target.result;
            if(items == undefined) {
              console.log("Couldn't find ingredient...", val);
              this.getIngredientFromUrl(title, keywords);
            } else {
              console.log("Found existing ingredient");
              this.ingredient = fullTitle;
              let nuteArray = await this.formatNutrients(items);
              this.getDescription(title, keywords);
              this.rawNutes = nuteArray;
              this.nutrients = this.putFirstItemLast(nuteArray);
            }
          }
        });
      }
    },
    computed: {
    // a computed getter
      unknowNutesString: function(){
        let nuteString = "";
        if(this.unknownNutes.length == 0) return "";
        this.unknownNutes.forEach((nute, index, unknownNutes)=>{
          if(unknownNutes.length == 1){
            nuteString += nute.toLowerCase();
          } else if(index == unknownNutes.length-1){
            nuteString += " og "+nute.toLowerCase();
          } else if(index == unknownNutes.length-2){
            nuteString += nute.toLowerCase();
          } else {
            nuteString += nute.toLowerCase()+", "
          }
        })
        return nuteString;
      },
    },
    watch:{
      '$route': 'getIngredient',
      nutrients: {
        handler:function () {
        // `this` points to the vm instance
        let analysisArray = [];
        if(this.nutrients.length == 0) return [];
        console.log(this.rawNutes);
        let nutrientRatio = false;
        this.nutrients.forEach(nutrientCat=>{
          if(nutrientCat.title === "Makro"){
            nutrientRatio = getNutrientRatio([...nutrientCat.nutrients, this.nutrients.find((cat)=>cat.title == "Ukategorisert").nutrients.find((nut)=>nut.title == "Alkohol")]);
            console.log(nutrientRatio);
            nutrientCat.nutrients.forEach((nutrient)=>{
              let value = nutrient.value;
              switch(nutrient.title){
                case "Kcal":
                  if(value <= 50){
                    analysisArray.push(["LOW_KCAL", "Lite kalorier"]);
                  } else if(value >= 150){
                    analysisArray.push(["HIGH_KCAL", "Ganske høyt innhold av kalorier"])
                  }
                break;
                case "Fett":
                  if(value < 0.5){
                    analysisArray.push(["VERY_LOW_FAT", "Veldig lite fett"]);
                  } else if(value < 3){
                    analysisArray.push(["LOW_FAT", "Lite fett"]);
                  }
                break;
                case "Fiber":
                  if(value > 10){
                    analysisArray.push(["HIGH_FIBER", "Rikelig med fiber"])
                  } else if(value > 5){
                    analysisArray.push(["MODERATE_FIBER","En del fiber"])
                  } else if(value > 2){
                    analysisArray.push(["LOW_FIBER", "Litt fiber"]);
                  } else if(value >= 0){
                    analysisArray.push("NO_FIBER", "Ingen eller svært lite fiber");
                  }
                break;
                case "Karbohydrater":
                  var sugarValue = nutrientCat.nutrients.find((searchNute)=>{
                    return searchNute.title == "Sukker"
                  }).value;
                  console.log("sugar value is", sugarValue);
                  if(value > 40 && sugarValue < 5){
                      analysisArray.push(["HIGH_CARB_LOW_SUGAR", "Mange karbohydrater men lite rent sukker", "https://nyheter.brukmat.no/"])
                  }
                break;
              }
            })
          }
        });
        const ctx = this.$refs.myChart;
        console.log(JSON.stringify(nutrientRatio));
        if(nutrientRatio.unknown_nutrients.length){
          this.unknownNutes = nutrientRatio.unknown_nutrients;
        }
        new Chart(ctx, {
          type: 'pie',
          data: {
              labels: ['Sukker', 'Protein', 'Stivelse', 'Fett', 'Andre karbohydrater', 'Ukjent', 'Alkohol'],
              datasets: [{
                  label: 'Kcal per Makronæring',
                  data: [nutrientRatio.sugar, nutrientRatio.protein, nutrientRatio.starch, nutrientRatio.fat, nutrientRatio.unknown_carbs, nutrientRatio.unknown, nutrientRatio.alcohol],
                  backgroundColor: [
                      'rgba(255, 99, 132, 0.2)',
                      'rgba(54, 162, 235, 0.2)',
                      'rgba(255, 206, 86, 0.2)',
                      'rgba(75, 192, 192, 0.2)',
                      'rgba(153, 102, 255, 0.2)',
                      'rgba(255, 159, 64, 0.2)',
                      'rgba(200, 200, 200, 0.2)'
                  ],
                  borderColor: [
                      'rgba(255, 99, 132, 1)',
                      'rgba(54, 162, 235, 1)',
                      'rgba(255, 206, 86, 1)',
                      'rgba(75, 192, 192, 1)',
                      'rgba(153, 102, 255, 1)',
                      'rgba(255, 159, 64, 1)',
                      'rgba(200, 200, 200, 1)'
                  ],
                  borderWidth: 1
              }]
          },
          options: {
              scales: {
                  y: {
                      beginAtZero: true
                  }
              }
          }
      });
        this.analyzedNutes = JSON.stringify(analysisArray);
      }
    }
  }
}
  function getNutrientRatio(macroNutrientArray){
    let nutrients = {};
    let unknownNutrients = [];
    try {
      macroNutrientArray.forEach((nutrient)=>{
        if(!nutrient.title) throw new Error("Nutrient title is not specified");
        else {
          let value = nutrient.value;
          if(value < 0){
            unknownNutrients.push(nutrient.title);
            value = 0;
          }
          nutrients[nutrient.title] = value;
        }
      });
    } catch(err){
      return false;
    }
    let sugarRatio = 3.87,
        starchRatio = 4,
        proteinRatio = 4,
        fatRatio = 9,
        alcoholRatio = 7;
    let kcal = {
      sugar: nutrients.Sukker*sugarRatio,
      carbs: nutrients.Karbohydrater*4,
      starch: nutrients.Stivelse*starchRatio,
      protein: nutrients.Protein*proteinRatio,
      fat: nutrients.Fett*fatRatio,
      alcohol: nutrients.Alkohol*alcoholRatio
    }
    
    let total = kcal.fat+kcal.protein+kcal.carbs+kcal.alcohol;
    let difference = nutrients.Kcal - total;
    //console.log("Nutrients", "s", sugarKcal, "sta", starchKcal, "p", proteinKcal, "f", fatKcal, " total:", total, "diff", difference);
    let energy = {
      ...kcal,
      unknown_nutrients:unknownNutrients,
      unknown_carbs:kcal.carbs-kcal.starch-kcal.sugar,
      unknown:difference
    }
    return energy;
  }
  function unflattenItem(data) {
    if(data == undefined){
      return [];
    }
    if(data.length == 0){
      return [];
    }
      let result = {};
      for (var i in data) {
        var keys = i.split(/_(.+)/);
        result[i] = data[i];
        if(keys.length > 1){
          if(!result[keys[0]]) result[keys[0]] = [];

          result[keys[0]].push({"value":data[i], "title":keys[1]});
        }
      }
      if(result["nutrients"]){
        result["nutrients"] = result["nutrients"].sort((a,b) => (a.title.localeCompare(b.title)));
      }

    return result;
  }
</script>